import _ from 'underscore';
import moment from 'moment';

import amount_rounded_to_cents from 'yinzhilv-js-sdk/src/common-service/amount_rounded_to_cents';

// 授信账户详情
import web_credit_creditBillList from "@/lib/data-service/default/web_credit_creditBillList";
// 授信日志列表
import web_credit_creditLogList from "@/lib/data-service/default/web_credit_creditLogList";

export default {
    data() {
        return {
            loading: false,
            dateRange: null,
            form: {
                currentPage: 1,
                pageSize: 10,
                tradingHourStart: "",
                tradingHourEnd: "",
                isOverdue: "",
                repayStatus: "0",
            },
            list: [],
            totalRefundAmount: 0,
            selectRefundAmount: 0,
            selectIds: "",
            pager: {
                total: 0,
                pageSizes: [10, 15, 20, 25, 30],
            },

            dateIndex: "",
            dateOptions: [
                {text: "今天", value: "1", diff: 0, type: "days"},
                {text: "最近一周", value: "2", diff: 1, type: "weeks"},
                {text: "最近一个月", value: "3", diff: 1, type: "months"},
                {text: "最近三个月", value: "4", diff: 3, type: "months"},
            ],
            overdueTypes: [
                {text: "全部", value: ""},
                {text: "是", value: "3"},
                {text: "否", value: "0"},
            ],
            statusList: [
                {text: "未还款", value: "0"},
                {text: "已还款", value: "1"},
            ],

            logVisible: false,
            logList: [],
            logLoading: false,
            logForm: {
                currentPage: 1,
                pageSize: 10,
                orderNo: "",
            },
            logPager: {
                total: 0,
                pageSizes: [10, 15, 20, 25, 30],
            },
            countDownTimer: null,
        };
    },
    methods: {
        req_list() {
            this.loading = true;
            this.countDownTimer = null;
            web_credit_creditBillList(this.form)
                .then((result) => {
                    const {pageResult, totalRefundAmount} = result;
                    pageResult.pageData.forEach((item) => {
                        item.isConfront = false;
                        item.timeRemaining = '';
                        // item.billRefundDate = '2021-11-06 16:32:00';
                    });
                    this.list = pageResult.pageData;
                    // this.list = _.chain(pageResult.pageData)
                    //     .filter(function (m, i) {
                    //         return i === 4;
                    //     })
                    //     .value()
                    // ;

                    this.pager.total = pageResult.totalCount;
                    this.totalRefundAmount = totalRefundAmount;
                    this.loading = false;
                    if (this.form.repayStatus == 0) this.count_down();
                })
                .catch((err) => {
                    this.loading = false;
                });
        },

        count_down() {
            const list = this.list;
            this.countDownTimer = setInterval(() => {
                const moment_now = moment();
                for (const item of list) {
                    const billRefundDate = item.billRefundDate;

                    if (billRefundDate) {
                        const moment_billRefundDate = moment(billRefundDate);
                        const diff = moment_billRefundDate.diff(moment_now);

                        if (diff > 0) {
                            //如果billRefundDate比当前时间晚

                            const diff_days = moment_billRefundDate.diff(moment_now, `days`);
                            const diff_hours = moment_billRefundDate.diff(moment_now, `hours`);
                            const diff_minutes = moment_billRefundDate.diff(moment_now, `minutes`);
                            const diff_seconds = moment_billRefundDate.diff(moment_now, `seconds`);

                            const duration_days = moment.duration(diff).as('days');
                            const duration_hours = moment.duration(diff).as('hours');
                            const duration_minutes = moment.duration(diff).as('minutes');
                            const duration_seconds = moment.duration(diff).as('seconds');

                            //结论：
                            //diff会向下取整
                            //duration不会向下取整

                            //如果billRefundDate比当前时间晚一天内
                            if (duration_days <= 1) {
                                item.isConfront = true;

                                const s_001 = diff_seconds % 60 % 60;
                                const s_002 = s_001.toString().padStart(2, `0`);
                                const m_001 = diff_minutes % 60;
                                const m_002 = m_001.toString().padStart(2, `0`);
                                const h_001 = diff_hours;
                                const h_002 = h_001.toString().padStart(2, `0`);
                                item.timeRemaining = `${h_002}:${m_002}:${s_002}`;
                            }
                        }

                        if (diff <= 0) {
                            item.isOverdue = 1;
                        }
                    }

                }
            }, 1000);
        },

        req_log_list() {
            this.logLoading = true;
            web_credit_creditLogList(this.logForm)
                .then((result) => {
                    const datas = result.pageResult;
                    this.logList = datas.pageData;
                    this.logPager.total = datas.totalCount;
                    this.logLoading = false;
                })
                .catch((err) => {
                    this.logList = [];
                    this.logPager.total = 0;
                    this.logLoading = false;
                });
        },

        search() {
            this.change_currentPage(1);
        },

        change_date(val) {
            const form = this.form;
            const moment = this.$moment;
            if (val) {
                form.tradingHourStart = val[0];
                form.tradingHourEnd = val[1];
                const now = moment();
                const endSrt = moment(now).format("YYYY-MM-DD");
                const dateOptions = this.dateOptions;
                for (let i = 0; i < dateOptions.length; i++) {
                    const item = dateOptions[i];
                    const {value, diff, type} = item;
                    const startStr = moment(endSrt)
                        .add(-diff, type)
                        .format("YYYY-MM-DD");
                    if (
                        form.tradingHourStart === startStr &&
                        form.tradingHourEnd === endSrt
                    ) {
                        this.dateIndex = value;
                        return this.search();
                    } else {
                        this.dateIndex = "";
                    }
                }
            } else {
                form.tradingHourStart = "";
                form.tradingHourEnd = "";
                this.dateIndex = "";
            }
            this.search();
        },
        selec_date_option(option) {
            const {value, diff, type} = option;
            this.dateIndex = value;
            const moment = this.$moment;
            const tradingHourStart = moment();
            const start = moment(tradingHourStart)
                .add(-diff, type)
                .format("YYYY-MM-DD");
            const end = tradingHourStart.format("YYYY-MM-DD");
            const dateRange = [start, end];
            this.dateRange = dateRange;
            this.change_date(dateRange);
        },

        change_overdueType(value) {
            this.form.isOverdue = value;
            this.search();
        },

        change_status(value) {
            this.form.repayStatus = value;
            this.search();
        },

        oneKey_repayment() {
            this.$confirm("是否确认一键还款?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$router.push({
                        name: "distributor-easy-pay-back-payment",
                        query: {
                            type: 1,
                        },
                    });
                })
                .catch(() => {
                });
        },

        select_repayment() {
            this.$router.push({
                name: "distributor-easy-pay-back-payment",
                query: {
                    type: 2,
                    ids: this.selectIds,
                },
            });
        },

        item_repayment(id) {
            this.$router.push({
                name: "distributor-easy-pay-back-payment",
                query: {
                    type: 2,
                    ids: id,
                },
            });
        },

        change_currentPage(currentPage) {
            this.form.currentPage = currentPage;
            this.req_list();
        },

        change_pageSize(pageSize) {
            this.form.pageSize = pageSize;
            this.search();
        },

        handleSelectionChange(val) {
            let selectRefundAmount = 0;
            let selectIds = [];

            for (const item of val) {
                selectIds.push(item.id);
                switch (item.billStatus.toString()) {
                    case '1':
                        selectRefundAmount += item.noPayedAmount;
                        break;
                    case '2':
                        selectRefundAmount += item.noPayedAmount;
                        break;
                    case '3':
                        break;
                    case '4':
                        selectRefundAmount +=
                            item.noPayedAmount + item.overdueFineLimitAmount;
                        break;
                }
            }

            this.selectIds = selectIds.join(",");
            const selectRefundAmount_string = amount_rounded_to_cents(selectRefundAmount);
            this.selectRefundAmount = selectRefundAmount_string;
        },

        show_repayment_log(orderNo) {
            this.logForm.orderNo = orderNo;
            this.logVisible = true;
            this.req_log_list();
        },

        close_repayment_log() {
            this.logVisible = false;
            const form = this.logForm;
            form.currentPage = 1;
            form.orderNo = "";
            this.logList = [];
        },
    },
    created() {
        this.req_list();
    },
};
